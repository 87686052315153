import styled from "@emotion/styled"
import { alpha, Grid, SvgIcon } from "@mui/material"
import { Box } from "@mui/system"
import { Button } from "gatsby-theme-material-ui"
import React from "react"
import img_female from "../assets/images/icons/female.svg"
import img_male from "../assets/images/icons/male.svg"
import withTrans from "./withTrans"

const ErrorButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
  color: theme.palette.error.dark,
  disableElevation: true,
  borderColor: theme.palette.error.dark,
  "&:hover": {
    backgroundColor: alpha(theme.palette.error.light, 0.5),
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  color: theme.palette.success.main,
  disableElevation: true,
  borderColor: theme.palette.success.dark,
  "&.MuiButton-contained": {},
  "&.MuiButton-outlined": {
    border: `1px solid #D0D5DD`,
    backgroundColor: alpha(theme.palette.success.light, 0.1),
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.success.light, 1),
  },
}))

const GenderSwitch = ({ error, value, onInput, t }) => {
  const Btn = error ? ErrorButton : StyledButton

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      columnSpacing={2}
    >
      <Grid item xs={4} sm={3} textAlign="right">
        <Box component="div" sx={{ width: "100%", textAlign: "center" }}>
          <SvgIcon
            component={img_male}
            viewBox="0 0 48 48"
            sx={{ height: 48, width: 48 }}
          />
        </Box>
        <Btn
          fullWidth
          color={value === "m" ? "success" : "primary"}
          variant={value === "m" ? "contained" : "outlined"}
          disableElevation
          onClick={() => onInput("m")}
        >
          {t("calculator.general.label.male")}
        </Btn>
      </Grid>
      <Grid item xs={4} sm={3} textAlign="left">
        <Box component="div" sx={{ width: "100%", textAlign: "center" }}>
          <SvgIcon
            component={img_female}
            viewBox="0 0 48 48"
            sx={{ height: 48, width: 48 }}
          />
        </Box>

        <Btn
          color={value === "f" ? "success" : "primary"}
          fullWidth
          disableElevation
          variant={value === "f" ? "contained" : "outlined"}
          onClick={() => onInput("f")}
        >
          {t("calculator.general.label.female")}
        </Btn>
      </Grid>
    </Grid>
  )
}

export default withTrans(GenderSwitch)
