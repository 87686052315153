import styled from "@emotion/styled"
import {
  Alert,
  alpha,
  Button,
  Container,
  Grid,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material"
import i18next from "i18next"
import React, { useEffect, useState } from "react"
import img_negative from "../assets/images/icons/icon-negative-dynamic.svg"
import img_neutral from "../assets/images/icons/icon-neutral-dynamic.svg"
import img_positive from "../assets/images/icons/icon-positive-dynamic.svg"
import PageHeadingIcon from "../assets/images/icons/kcal-green.svg"
import ActivTypeSwitch from "../components/ActiveTypeSwitch"
import AdAppCounting from "../components/AdAppCounting"
import CalcInputBox from "../components/CalcInputBox"
import GenderSwitch from "../components/GenderSwitch"
import Layout from "../components/Layout"
import PageHeading from "../components/PageHeading"
import Seo from "../components/Seo"
import Textblock from "../components/Textblock"
import TextInput from "../components/TextInput"
import withTrans from "../components/withTrans"
import theme from "../theme"

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  color: theme.palette.success.main,
  disableElevation: true,
  borderColor: theme.palette.success.dark,
  "&.MuiButton-contained": {},
  "&.MuiButton-outlined": {
    border: `1px solid #D0D5DD`,
    backgroundColor: alpha(theme.palette.success.light, 0.1),
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.success.light, 1),
  },
}))

const CalcCalorieRequirement = ({ t }) => {
  const upMd = useMediaQuery(theme.breakpoints.up("md"))

  // errors
  const [errors, setErrors] = useState([])

  // inputs
  const [heightCm, setHeightCm] = useState("")
  const [heightFt, setHeightFt] = useState("")
  const [heightIn, setHeightIn] = useState("")
  const [weightKg, setWeightKg] = useState("")
  const [weightLb, setWeightLb] = useState("")
  const [unitHeight, setUnitHeight] = useState("metric")
  const [unitWeight, setUnitWeight] = useState("metric")
  const [gender, setGender] = useState("")
  const [age, setAge] = useState("")
  const [activityType, setActivityType] = useState("")

  // calculated
  const [isValid, setIsValid] = useState(false)
  const [looseCalories, setLooseCalories] = useState(0)
  const [maintainCalories, setMaintainCalories] = useState(0)
  const [gainCalories, setGainCalories] = useState(0)

  // ui
  const [showError, setShowError] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    calculate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gender,
    age,
    unitHeight,
    unitWeight,
    heightCm,
    heightFt,
    heightIn,
    weightKg,
    weightLb,
    activityType,
    submitted,
    showError,
  ])

  useEffect(() => {
    if (shouldScroll) {
      var anchor = document.querySelector('#result-container');
      if (anchor) {
        setTimeout(() => {
          anchor.scrollIntoView({ behavior: "smooth", block: "start" })
        }, 50)
      }
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  const handleUnitHeightChange = value => {
    setUnitHeight(value)
  }

  const roundWithIncrement = (number, increment, offset) => {
    return Math.ceil((number - offset) / increment) * increment + offset
  }

  const handleUnitWeightChange = value => {
    setUnitWeight(value)
  }

  const handleGenderChange = value => {
    setGender(value)
    const newErrors = errors.filter(e => e !== "gender")
    setErrors(newErrors)
  }

  const handleAgeChange = event => {
    const newAge = parseInt(event.target.value)
    if (Number.isInteger(newAge)) {
      setAge(event.target.value)
      const newErrors = errors.filter(e => e !== "age")
      setErrors(newErrors)
    } else {
      setAge("")
    }
  }

  const handleMetricHeightChange = event => {
    const newHeight = parseInt(event.target.value)
    if (Number.isInteger(newHeight)) {
      setHeightCm(event.target.value)
      const newErrors = errors.filter(e => e !== "height")
      setErrors(newErrors)
    } else {
      setHeightCm("")
    }
  }

  const handleImperialHeightChange = (part, value) => {
    const newHeight = parseInt(value)

    if (Number.isInteger(newHeight)) {
      if (part === "ft") {
        setHeightFt(value)
      }

      if (part === "in") {
        setHeightIn(value)
      }
      const newErrors = errors.filter(e => e !== "height")
      setErrors(newErrors)
    } else {
      if (part === "ft") {
        setHeightFt("")
      }

      if (part === "in") {
        setHeightIn("")
      }
    }
  }

  const handleActivityTypeChange = value => {
    setActivityType(value)
    const newErrors = errors.filter(e => e !== "activityType")
    setErrors(newErrors)
  }

  const handleWeightChange = value => {
    const newHeight = parseInt(value)
    if (Number.isInteger(newHeight)) {
      if (unitWeight === "metric") {
        setWeightKg(value)
      } else {
        setWeightLb(value)
      }
      const newErrors = errors.filter(e => e !== "weight")
      setErrors(newErrors)
    } else {
      if (unitWeight === "metric") {
        setWeightKg("")
      } else {
        setWeightLb("")
      }
    }
  }

  const validate = () => {
    const newErrors = []
    if (!gender) {
      newErrors.push("gender")
    }
    if (!age) {
      newErrors.push("age")
    }

    if (unitHeight === "metric") {
      if (!heightCm) {
        newErrors.push("height")
      }
    }

    if (unitHeight === "imperial") {
      if (!heightFt || !heightIn) {
        newErrors.push("height")
      }
    }

    if (unitWeight === "metric") {
      if (!weightKg) {
        newErrors.push("weight")
      }
    }

    if (unitWeight === "imperial") {
      if (!weightLb) {
        newErrors.push("weight")
      }
    }

    if (newErrors.length > 0 && submitted) {
      setShowError(true)
    }

    submitted && setErrors(newErrors)

    if (newErrors.length < 1) {
      setIsValid(true)
      return true
    } else {
      setIsValid(false)
      return false
    }
  }

  const getCalorieTargetForGoal = (
    goal,
    activityPal,
    gender,
    startWeight,
    height,
    age
  ) => {
    let targetWeight = startWeight * 0.9
    let weightChangePerWeek = 0.5
    let a = 1
    let b = 1

    let genderFactor = gender === "m" ? 5 : -161

    let bmr = 10 * startWeight + 6.25 * height - 5 * age + genderFactor

    if (goal === "loose") {
      weightChangePerWeek = -0.5
    }
    if (goal === "gain") {
      weightChangePerWeek = +0.5
    }

    if (goal !== "maintain") {
      a = (startWeight - targetWeight) * 750
      b = (startWeight - targetWeight) / weightChangePerWeek
    }

    let palSleeping = 0.95
    let genderDefaultPal = (activityPal * 16 + palSleeping * 8) / 24

    return bmr * genderDefaultPal + a / b
  }

  const calculate = () => {
    if (!validate()) {
      return false
    }

    if (errors.length > 0) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }

    if (unitHeight === "imperial") {
      let heightInches = heightFt * 12 + parseInt(heightIn)
      setHeightCm(heightInches * 2.54)
    }

    if (unitWeight === "imperial") {
      let weightKg = weightLb / 2.2046
      setWeightKg(weightKg)
    }

    setLooseCalories(
      getCalorieTargetForGoal(
        "loose",
        activityType,
        gender,
        weightKg,
        heightCm,
        age
      )
    )
    setMaintainCalories(
      getCalorieTargetForGoal(
        "maintain",
        activityType,
        gender,
        weightKg,
        heightCm,
        age
      )
    )
    setGainCalories(
      getCalorieTargetForGoal(
        "gain",
        activityType,
        gender,
        weightKg,
        heightCm,
        age
      )
    )
  }

  const handleShowResult = () => {
    setSubmitted(true)
    validate()
    setShowError(!isValid)
    setShowResult(true)
    setShouldScroll(true)
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleShowResult();
    }
  }

  return (
    <Layout pageName="start" theme={theme}>
      <Container onKeyDown={handleKeyDown}>
        <PageHeading
          title={t("calculator_page.calorie_intake.heading")}
          icon={PageHeadingIcon}
        >
          {t("calculator_page.calorie_intake.teaser.text", "")}
        </PageHeading>
        <Grid
          container
          sx={{ mt: theme.spacing(4) }}
          rowSpacing={1}
          columnSpacing={{ md: theme.spacing(2) }}
        >
          <Grid item xs={12} md={6}>
            <CalcInputBox
              error={errors.includes("gender")}
              num={1}
              title={t("calculator.general.label.gender")}
            >
              <GenderSwitch
                error={errors.includes("gender")}
                onInput={handleGenderChange}
                value={gender}
              />
            </CalcInputBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <CalcInputBox
              error={errors.includes("age")}
              num={2}
              title={t("calculator.general.label.age")}
            >
              <TextInput
                placeholder="21"
                label={""}
                type="number"
                error={errors.includes("age")}
                variant="standard"
                value={age}
                onChange={handleAgeChange}
              />
              <Typography
                variant="body1"
                component="div"
                sx={{
                  display: "inline-block",
                  lineHeight: { xs: "3rem", md: "3.125rem" },
                }}
              >
                {t("calculator.general.label.years")}
              </Typography>
            </CalcInputBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <CalcInputBox
              error={errors.includes("height")}
              num={3}
              title={t("calculator.general.label.height")}
            >
              {unitHeight === "metric" ? (
                <>
                  <TextInput
                    error={errors.includes("height")}
                    placeholder="170"
                    type="number"
                    value={heightCm}
                    onChange={handleMetricHeightChange}
                    label={""}
                  />
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      display: "inline-block",
                      lineHeight: { xs: "3rem", md: "3.125rem" },
                    }}
                  >
                    {t("system.general.unit.cm", "cm")}
                  </Typography>
                </>
              ) : (
                <>
                  <TextInput
                    error={errors.includes("height")}
                    placeholder="5"
                    value={heightFt}
                    type="number"
                    onChange={event =>
                      handleImperialHeightChange("ft", event.target.value)
                    }
                    label={""}
                  />
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      display: "inline-block",
                      lineHeight: { xs: "3rem", md: "3.125rem" },
                    }}
                  >
                    {t("system.general.unit.ft", "ft")}
                  </Typography>
                  <TextInput
                    error={errors.includes("height")}
                    placeholder="6"
                    value={heightIn}
                    type="number"
                    onChange={event =>
                      handleImperialHeightChange("in", event.target.value)
                    }
                    label={""}
                  />
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      display: "inline-block",
                      lineHeight: { xs: "3rem", md: "3.125rem" },
                    }}
                  >
                    {t("system.general.unit.in", "in")}
                  </Typography>
                </>
              )}

              <Grid
                container
                direction="row"
                justifyContent="center"
                columnSpacing={2}
                mt={2}
              >
                <Grid item xs={4} textAlign="right">
                  <StyledButton
                    disableElevation
                    color={errors.includes("height") ? "error" : "secondary"}
                    onClick={() => handleUnitHeightChange("metric")}
                    variant={unitHeight === "metric" ? "contained" : "outlined"}
                  >
                    {t("system.general.unit.cm", "cm")}
                  </StyledButton>
                </Grid>
                <Grid item xs={4} textAlign="left">
                  <StyledButton
                    disableElevation
                    color={errors.includes("height") ? "error" : "secondary"}
                    variant={
                      unitHeight === "imperial" ? "contained" : "outlined"
                    }
                    onChange={handleUnitHeightChange}
                    onClick={() => handleUnitHeightChange("imperial")}
                  >
                    {t("system.general.unit.ft", "ft")}/
                    {t("system.general.unit.in", "in")}
                  </StyledButton>
                </Grid>
              </Grid>
            </CalcInputBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <CalcInputBox
              error={errors.includes("weight")}
              num={4}
              title={t("calculator.general.label.weight")}
            >
              {unitWeight === "metric" ? (
                <>
                  <TextInput
                    error={errors.includes("weight")}
                    placeholder="60"
                    value={weightKg}
                    type="number"
                    onChange={event => handleWeightChange(event.target.value)}
                    label={""}
                  />
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      display: "inline-block",
                      lineHeight: { xs: "3rem", md: "3.125rem" },
                    }}
                  >
                    {t("system.general.unit.kg")}
                  </Typography>
                </>
              ) : (
                <>
                  <TextInput
                    error={errors.includes("weight")}
                    placeholder="160"
                    value={weightLb}
                    type="number"
                    onChange={event => handleWeightChange(event.target.value)}
                    label={""}
                  />
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      display: "inline-block",
                      lineHeight: { xs: "3rem", md: "3.125rem" },
                    }}
                  >
                    {t("system.general.unit.lb")}
                  </Typography>
                </>
              )}

              <Grid
                container
                direction="row"
                justifyContent="center"
                columnSpacing={2}
                mt={2}
              >
                <Grid item xs={4} textAlign="right">
                  <StyledButton
                    disableElevation
                    color={errors.includes("weight") ? "error" : "secondary"}
                    onClick={() => handleUnitWeightChange("metric")}
                    variant={unitWeight === "metric" ? "contained" : "outlined"}
                  >
                    {t("system.general.unit.kg")}
                  </StyledButton>
                </Grid>
                <Grid item xs={4} textAlign="left">
                  <StyledButton
                    disableElevation
                    color={errors.includes("weight") ? "error" : "secondary"}
                    onClick={() => handleUnitWeightChange("imperial")}
                    variant={
                      unitWeight === "imperial" ? "contained" : "outlined"
                    }
                  >
                    {t("system.general.unit.lb")}
                  </StyledButton>
                </Grid>
              </Grid>
            </CalcInputBox>
          </Grid>
          <Grid item xs={12} md={12}>
            <CalcInputBox
              error={errors.includes("weight")}
              num={5}
              height="230px"
              title={t("calculator.daily_need.label.activity")}
            >
              <ActivTypeSwitch
                error={errors.includes("activityType")}
                onInput={handleActivityTypeChange}
                value={activityType}
              />
            </CalcInputBox>
          </Grid>
          <Grid item xs={12} textAlign="center">
            {showError && (
              <Alert
                onClose={() => {
                  setShowError(false)
                }}
                variant="outlined"
                icon={false}
                sx={{
                  mt: theme.spacing(2),
                  backgroundColor: theme.palette.error.light,
                  color: theme.palette.error.dark,
                  fontSize: { xs: "1rem" },
                  lineHeight: { xs: "1.5rem" },
                }}
                severity="error"
              >
                <div dangerouslySetInnerHTML={{ __html: t('calculator.general.message.complete_form') }} />
              </Alert>
            )}

            <Button
              fullWidth={!upMd}
              size="large"
              variant="contained"
              onClick={handleShowResult}
              sx={{
                backgroundColor: theme.palette.secondary.dark,
                mt: { xs: 6, md: 4 },
                width: { md: 360 },
                '&:hover': {
                  backgroundColor: theme.palette.secondary.dark, // same as the non-hover color
                },
                '&.MuiButton-contained.Mui-focusVisible': {
                  backgroundColor: theme.palette.secondary.dark, // same as the non-hover color
                },
              }}
              disableElevation
            >
              {t("calculator.daily_need.button.calculate")}
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Container id={"result-container"} style={{ display: showResult && submitted && isValid ? 'block' : 'none' }}>
        <Container>
          <Typography
            sx={{
              fontWeight: 600,
              textAlign: "center",
              my: theme.spacing(8),
            }}
            variant="h5"
          >
            {t("calculator.general.headline.result")}
          </Typography>
          <Grid container justifyContent="center" columnSpacing={3}>
            <Grid item xs={12} md={4}>
              <CalcInputBox num={false} height="350px">
                <Typography
                  variant="body1"
                  p={0}
                  sx={{
                    fontSize: "1.375rem",
                    lineHeight: "2rem",
                    fontWeight: "600",
                    color: "#101828",
                  }}
                >
                  {t("calculator.daily_need.label.lose_weight")}
                </Typography>
                <SvgIcon
                  component={img_negative}
                  viewBox="0 0 48 48"
                  sx={{ height: 72, width: 72 }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    fontWeight: "600",
                    color: "#101828",
                  }}
                >
                  {t("calculator.daily_need.label.calories_per_day")}
                </Typography>
                <Typography
                  my={1}
                  sx={{
                    fontWeight: "600",
                    display: "block",
                    color: "#101828",
                    fontSize: { xs: "1.5rem" },
                    lineHeight: { xs: "2rem" },
                  }}
                  component="span"
                >
                  {`${roundWithIncrement(
                    looseCalories,
                    10,
                    10
                  )} - ${roundWithIncrement(
                    looseCalories + 100,
                    10,
                    10
                  )}`}{" "}
                  {t("system.general.unit.kcal")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#101828", mb: 2 }}
                  mb={4}
                >
                  {t("calculator.daily_need.content.lose_weight")}
                </Typography>
              </CalcInputBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <CalcInputBox num={false} height="350px">
                <Typography
                  sx={{
                    fontSize: "1.375rem",
                    lineHeight: "2rem",
                    fontWeight: "600",
                    color: "#101828",
                  }}
                >
                  {t("calculator.daily_need.label.maintain_weight")}
                </Typography>
                <SvgIcon
                  component={img_neutral}
                  viewBox="0 0 48 48"
                  sx={{ height: 72, width: 72 }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    fontWeight: "600",
                    color: "#101828",
                  }}
                >
                  {t("calculator.daily_need.label.calories_per_day")}
                </Typography>
                <Typography
                  my={1}
                  sx={{
                    fontWeight: "600",
                    display: "block",
                    color: "#101828",
                    fontSize: { xs: "1.5rem" },
                    lineHeight: { xs: "2rem" },
                  }}
                  component="span"
                >
                  {`${roundWithIncrement(
                    maintainCalories - 100,
                    10,
                    10
                  )} - ${roundWithIncrement(
                    maintainCalories + 100,
                    10,
                    10
                  )}`}{" "}
                  {t("system.general.unit.kcal")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#101828", mb: 2 }}
                  mb={4}
                >
                  {t("calculator.daily_need.content.maintain_weight")}
                </Typography>
              </CalcInputBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <CalcInputBox num={false} height="350px">
                <Typography
                  sx={{
                    fontSize: "1.375rem",
                    lineHeight: "2rem",
                    fontWeight: "600",
                    color: "#101828",
                  }}
                >
                  {t("calculator.daily_need.label.gain_weight")}
                </Typography>
                <SvgIcon
                  component={img_positive}
                  viewBox="0 0 48 48"
                  sx={{ height: 72, width: 72 }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    fontWeight: "600",
                    color: "#101828",
                  }}
                >
                  {t("calculator.daily_need.label.calories_per_day")}
                </Typography>
                <Typography
                  my={1}
                  sx={{
                    fontWeight: "600",
                    display: "block",
                    color: "#101828",
                    fontSize: { xs: "1.5rem" },
                    lineHeight: { xs: "2rem" },
                  }}
                  component="span"
                >
                  {`${roundWithIncrement(
                    gainCalories - 100,
                    10,
                    10
                  )} - ${roundWithIncrement(
                    gainCalories + 100,
                    10,
                    10
                  )}`}{" "}
                  {t("system.general.unit.kcal")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#101828", mb: 2 }}
                  mb={4}
                >
                  {t("calculator.daily_need.content.gain_weight")}
                </Typography>
              </CalcInputBox>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container>
        {i18next.exists('calculator_page.calorie_intake.bottom.text') && (
          <Textblock mt={6}>
            <div dangerouslySetInnerHTML={{ __html: t('calculator_page.calorie_intake.bottom.text') }} />
          </Textblock>
        )}
        <AdAppCounting mt={6} />
      </Container>
    </Layout>
  )
}

export default withTrans(CalcCalorieRequirement)

export const Head = () => (
  <Seo title={i18next.t("calculator_page.calorie_intake.title")} />
)
