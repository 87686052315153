import { Avatar, CardContent, Icon, Paper, Typography } from "@mui/material"
import Card from "@mui/material/Card"
import * as React from "react"
import theme from "../theme"

const CalcInputBox = ({ num, title, error, children, height }) => {
  const borderColor = error ? theme.palette.error.main : "#D0D5DD"
  const mdHeight = height ? height : "210px"
  return (
    <Paper p={0} elevation={0} sx={{ textAlign: "center" }}>
      {num && (
        <Avatar
          sx={{
            width: 32,
            height: 32,
            backgroundColor: "secondary.main",
            margin: "0 auto",
          }}
        >
          <Icon sx={{ fontSize: 18 }}>{num}</Icon>
        </Avatar>
      )}
      <Card
        variant="outlined"
        sx={{
          borderRadius: { xs: "none", md: 2 },
          borderBottom: { xs: "none", md: `2px solid ${borderColor}` },
          borderTop: {
            xs: "3px solid #D0D5DD",
            md: `2px solid ${borderColor}`,
          },
          borderLeft: { xs: "none", md: `2px solid ${borderColor}` },
          borderRight: { xs: "none", md: `2px solid ${borderColor}` },
          textAlign: "center",
          marginTop: -2,
          height: { md: mdHeight },
          py: { xs: num ? theme.spacing(2) : 0 },
        }}
      >
        <CardContent>
          <Typography variant="h2" sx={{ mt: 0, color: "#344054" }}>
            {title}
          </Typography>
          {children}
        </CardContent>
      </Card>
    </Paper>
  )
}

export default CalcInputBox
