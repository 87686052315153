import { TextField } from "@mui/material";
import React, { useRef } from "react";

const TextInput = ({type, ...props}) => {
  const inputRef = useRef(null);
  const isNumber = type === 'number';

  const handleFocus = () => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 90);  // Add a delay of 2000 milliseconds (2 seconds)
    }
  };


  return (
    <TextField
      InputProps={{
        inputProps: {
          style: { textAlign: "center" },
          type: isNumber ? 'number' : 'text',
          pattern: isNumber ? '[0-9]*' : undefined,
          inputmode: isNumber ? 'numeric' : 'text',
        },
        min: 0,
        style: {
          fontSize: 30,
          fontWeight: 700,
          color: "#344054",
        },
      }}
      sx={{
        width: 75,
      }}
      {...props}
      variant="standard"
      onFocus={handleFocus}
      ref={inputRef}
    />
  );
};

export default TextInput;
