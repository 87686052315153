import styled from "@emotion/styled"
import { alpha, Grid, SvgIcon } from "@mui/material"
import { Box } from "@mui/system"
import { Button } from "gatsby-theme-material-ui"
import React from "react"
import img_handling from "../assets/images/icons/icon-manual-handling.svg"
import img_sitting from "../assets/images/icons/icon-sitting-on-chair.svg"
import img_standing from "../assets/images/icons/icon-stand-up.svg"
import img_walking from "../assets/images/icons/icon-walking.svg"
import withTrans from "./withTrans"

const ErrorButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
  color: theme.palette.error.dark,
  disableElevation: true,
  borderColor: theme.palette.error.dark,
  "&:hover": {
    backgroundColor: alpha(theme.palette.error.light, 0.5),
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  color: theme.palette.success.main,
  disableElevation: true,
  borderColor: theme.palette.success.dark,
  "&.MuiButton-contained": {},
  "&.MuiButton-outlined": {
    border: `1px solid #D0D5DD`,
    backgroundColor: alpha(theme.palette.success.light, 0.1),
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.success.light, 1),
  },
}))

const ActivTypeSwitch = ({ error, value, onInput, t }) => {
  const Btn = error ? ErrorButton : StyledButton

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      columnSpacing={3}
      rowSpacing={3}
    >
      <Grid item xs={6} sm={3}>
        <Box component="div" sx={{ width: "100%", textAlign: "center" }}>
          <SvgIcon
            component={img_sitting}
            viewBox="0 0 72 72"
            sx={{ height: 72, width: 72 }}
          />
        </Box>
        <Btn
          fullWidth
          color={value === 1.4 ? "success" : "primary"}
          variant={value === 1.4 ? "contained" : "outlined"}
          disableElevation
          onClick={() => onInput(1.4)}
        >
          {t("calculator.daily_need.label.lightly_active")}
        </Btn>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Box component="div" sx={{ width: "100%", textAlign: "center" }}>
          <SvgIcon
            component={img_standing}
            viewBox="0 0 72 72"
            sx={{ height: 72, width: 72 }}
          />
        </Box>

        <Btn
          color={value === 1.6 ? "success" : "primary"}
          fullWidth
          disableElevation
          variant={value === 1.6 ? "contained" : "outlined"}
          onClick={() => onInput(1.6)}
        >
          {t("calculator.daily_need.label.moderately_active")}
        </Btn>
      </Grid>

      <Grid item xs={6} sm={3}>
        <Box component="div" sx={{ width: "100%", textAlign: "center" }}>
          <SvgIcon
            component={img_walking}
            viewBox="0 0 72 72"
            sx={{ height: 72, width: 72 }}
          />
        </Box>

        <Btn
          color={value === 1.8 ? "success" : "primary"}
          fullWidth
          disableElevation
          variant={value === 1.8 ? "contained" : "outlined"}
          onClick={() => onInput(1.8)}
        >
          {t("calculator.daily_need.label.active")}
        </Btn>
      </Grid>

      <Grid item xs={6} sm={3}>
        <Box component="div" sx={{ width: "100%", textAlign: "center" }}>
          <SvgIcon
            component={img_handling}
            viewBox="0 0 72 72"
            sx={{ height: 72, width: 72 }}
          />
        </Box>

        <Btn
          color={value === 2 ? "success" : "primary"}
          fullWidth
          disableElevation
          variant={value === 2 ? "contained" : "outlined"}
          onClick={() => onInput(2)}
        >
          {t("calculator.daily_need.label.very_active")}
        </Btn>
      </Grid>
    </Grid>
  )
}

export default withTrans(ActivTypeSwitch)
